import { Heading2 } from "components/text/heading/style";
import React from "react";
import bellacorp_theme from "style/theme_colors";
import { ConehcaContent, SegundaParteImageContent } from "./style";
import CallToAction from "components/call_to_action";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import image1 from "../../../../assets/webp/imagem_comparacao_1-min.webp";
import image2 from "../../../../assets/webp/imagem_comparacao_2-min.webp";
import image3 from "../../../../assets/webp/imagem_comparacao_3-min.webp";
import image4 from "../../../../assets/webp/imagem_comparacao_4-min.webp";

const SegundaParte: React.FC = () => {
  return (
    <ConehcaContent id="regioes_trataveis">
      <div className="title">
        <Heading2
          textAlign="center"
          color={bellacorp_theme.colors.black}
          fontFamily="GoldplaySemibold"
        >
          Conheça nossos ótimos resultados
        </Heading2>
      </div>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={20}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          400: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
          865: {
            slidesPerView: 3,
          },
          1000: {
            slidesPerView: 3,
          },
          1500: {
            slidesPerView: 3,
          },
          1700: {
            slidesPerView: 3,
          },
        }}
      >
        <SwiperSlide>
          <SegundaParteImageContent>
            <img src={image1} alt="Resultado 1" loading="lazy" />
          </SegundaParteImageContent>
        </SwiperSlide>
        <SwiperSlide>
          <SegundaParteImageContent>
            <img src={image2} alt="Resultado 2" loading="lazy" />
          </SegundaParteImageContent>
        </SwiperSlide>
        <SwiperSlide>
          <SegundaParteImageContent>
            <img src={image3} alt="Resultado 3" loading="lazy" />
          </SegundaParteImageContent>
        </SwiperSlide>
        <SwiperSlide>
          <SegundaParteImageContent>
            <img src={image4} alt="Resultado 4" loading="lazy" />
          </SegundaParteImageContent>
        </SwiperSlide>
      </Swiper>
      <CallToAction label={"Agendar Avaliação"} color="dourado" />
    </ConehcaContent>
  );
};

export default SegundaParte;
