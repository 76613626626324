import bellacorp_theme from "style/theme_colors";
import styled from "styled-components";

export const ConehcaContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 52px;

  @media screen and (max-width: 1100px) {
    padding: 0px 16px;
    gap: 20px;
  }
  @media screen and (max-width: 800px) {
    margin-top: 32px;
    padding: 0px 0px;
    gap: 34px;
  }
  .title {
    display: flex;
    flex-direction: column;
  }
`;
export const ConhecaCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 1100px) {
    gap: 8px;
  }
  @media screen and (max-width: 800px) {
    gap: 2px;
  }
  .line {
    background: ${bellacorp_theme.colors.tertiary};
    height: 1px;
    opacity: 0.5;
  }
  .text {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    align-content: center;
    flex-direction: row;
  }
`;
export const ConhecaGrid = styled.div`
  padding-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    padding-top: 20px;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    padding-top: 15px;
  }
`;
export const SegundaParteImageContent = styled.div`
  text-align: center;

  img {
    width: 90%;
    object-fit: cover;
    -webkit-box-shadow: -1px 0px 6px 0px rgb(0 0 0 / 62%);
    -moz-box-shadow: -1px 0px 6px 0px rgb(0 0 0 / 62%);
    box-shadow: -1px 0px 6px 0px rgb(0 0 0 / 62%);
  }
  @media screen and (max-width: 1100px) {
    img {
      width: 60%;
      max-width: 100%;
      margin-bottom: 35px;
    }
  }
  @media screen and (max-width: 800px) {
    img {
      width: 60%;
      max-width: 100%;
      margin-bottom: 35px;
    }
  }
`;
