import React from "react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { ContentWithHeader, PrimeiraParteContent } from "./style";
import {
  Heading1,
  Heading2,
} from "./../../../../components/text/heading/style";
import bellacorp_theme from "style/theme_colors";
import { Body1 } from "components/text/body/style";
import CallToAction from "components/call_to_action";
import Header from "components/header";
const PrimeiraParte: React.FC = () => {
  return (
    <ContentWithHeader id="nossos_resultados">
      <Header />
      <PrimeiraParteContent>
        <div className="text_content">
          <div className="title">
            <Heading2
              color={bellacorp_theme.colors.tertiary}
              className="titleMax"
            >
              Eliminamos sua gordura em 60 dias
            </Heading2>

            <Heading1
              color={bellacorp_theme.colors.tertiary}
              fontFamily="GoldplayBold"
            >
              SEM CIRURGIA PLÁSTICA.
            </Heading1>
          </div>
          <div className="text2">
            <Body1 color={bellacorp_theme.colors.tertiary} fontWeight={500}>
              Criomodelagem Sculpt é um procedimento para reduzir gordura
              localizada, promovendo uma harmonização corporal.
            </Body1>
          </div>
          <Body1 color={bellacorp_theme.colors.tertiary} fontWeight={500}>
            Resultado em SESSÃO ÚNICA!
          </Body1>

          <div className="button">
            <CallToAction label={"Agendar Avaliação"} color="dourado" />
          </div>
        </div>
        <div className="imasddas">
          <div className="image_content">
            <div className="trem_img"></div>
          </div>
        </div>
      </PrimeiraParteContent>
    </ContentWithHeader>
  );
};

export default PrimeiraParte;
