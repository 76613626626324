import styled from "styled-components";
import image_back from "../../../../assets/webp/background-min.webp";
export const FooterContent = styled.div`
  background-image: url(${image_back});
  margin: 60px 0px 0px 0px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 26px;

  .divFlex {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 24px;
  }
  section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 1100px;
    margin: 0 auto;
  }

  @media screen and (max-width: 800px) {
    background-size: cover;
    margin: 0px 0px 0px 0px;
    text-align: left;
    .divFlex {
      gap: 12px;
    }
    section {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }
  }
`;

export const FooterContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
