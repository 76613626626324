import styled from "styled-components";
import image_back from "../../../../assets/webp/background-min.webp";

export const AgenteJaContent = styled.div`
  background-image: url(${image_back});
  margin: 60px 0px;
  padding: 40px 0px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  iframe {
    width: 100%;
    height: 500px;
  }
  .text {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .text1 {
    display: none;
    gap: 20px;
    flex-direction: column;
  }
  .inside {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 20px;
  }
  @media screen and (max-width: 1100px) {
    margin: 40px 0px;
    padding: 40px 26px;
    background-size: cover;
    .inside {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    .text {
      display: none;
    }
    .text1 {
      display: flex;
    }
  }
  @media screen and (max-width: 800px) {
    margin: 40px 0px;
    padding: 40px 26px;
    .inside {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .bAJzzL iframe {
      height: 400px;
      margin: 0 auto;
    }
    .text {
      display: none;
    }
    .text1 {
      display: flex;
    }
  }
`;
