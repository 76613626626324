import styled from "styled-components";

export const CallToActionContent = styled.div<{ $isSecundary: boolean }>`
  background-color: ${(props) => (props.$isSecundary ? "transparent" : "#fff")};
  background-image: ${(props) =>
    props.$isSecundary
      ? "linear-gradient(180deg, rgb(235, 220, 164) 0%, rgb(186, 159, 71) 100%)"
      : "#fff"};
  border-radius: 7px;
  max-width: 250px;
  padding: 8px 24px;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  gap: 12px;
  transition: 200ms;
`;
export const CallToActionAction = styled.a`
  margin: 0 auto;
  cursor: pointer;
  text-decoration: none;
  ::placeholder {
    zoom: 120%;
    transition: 200ms;
  }
`;
