import { Body2 } from "components/text/body/style";
import { Heading4 } from "components/text/heading/style";
import React from "react";
import bellacorp_theme from "style/theme_colors";
import CallToAction from "components/call_to_action";
import { AgenteJaContent } from "./style";

const TerceiraParte: React.FC = () => {
  return (
    <AgenteJaContent id="como_funciona">
      <div className="inside">
        <div className="text1">
          <Heading4
            fontFamily="GoldplayBold"
            color={bellacorp_theme.colors.white}
          >
            Como funciona a Criomodelagem Sculpt
          </Heading4>
          <Body2
            textAlign="left"
            color={bellacorp_theme.colors.white}
            lineHeight="22px"
          >
            A Criomodelagem é o tratamento estético mais comentado do momento.
            Febre nas clínicas de estética, o famoso “congelador de gorduras”
            conquista cada vez mais adeptos por ser inovador, não invasivo e
            eficaz para redução de gordura localiza.
          </Body2>
        </div>
        <iframe
          src="https://player.vimeo.com/video/1000634917?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameBorder="0"
          allow="fullscreen; clipboard-write"
          title="Crioplapólise - Bella corp"
          loading="lazy"
        ></iframe>

        <script src="https://player.vimeo.com/api/player.js"></script>

        <div className="text">
          <Heading4
            fontFamily="GoldplayBold"
            color={bellacorp_theme.colors.white}
          >
            Como funciona a Criomodelagem Sculpt
          </Heading4>
          <Body2
            textAlign="left"
            color={bellacorp_theme.colors.white}
            lineHeight="22px"
          >
            A Criomodelagem é o tratamento estético mais comentado do momento.
            Febre nas clínicas de estética, o famoso “congelador de gorduras”
            conquista cada vez mais adeptos por ser inovador, não invasivo e
            eficaz para redução de gordura localiza.
          </Body2>
          <Body2
            textAlign="left"
            color={bellacorp_theme.colors.white}
            lineHeight="22px"
          ></Body2>
          <CallToAction label={"Agendar Avaliação"} />
        </div>
        <div className="text1">
          <CallToAction label={"Agendar Avaliação"} />
        </div>
      </div>
    </AgenteJaContent>
  );
};

export default TerceiraParte;
