import {
  BellaCorpHeaderContent,
  BellaLinks,
  BellaMenu,
  BellaResponsiveMenu,
  HeaderLogos,
} from "./style";
import React, { useState } from "react";
import logo from "../../assets/webp/logo2-min.webp";
import bellacorp_theme from "style/theme_colors";
import { Body1 } from "components/text/body/style";
import { Heading1 } from "components/text/heading/style";

const BellaCorpHeader: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <BellaCorpHeaderContent>
      <HeaderLogos>
        <img src={logo} alt="Logo" loading="lazy" />
      </HeaderLogos>
      <BellaLinks>
        <a href="#nossos_resultados">Nossos resultados</a>
        <a href="#nossos_resultados">Como funciona</a>
        <a href="#regioes_trataveis">Regiões que podem ser tratadas</a>
        <a href="#contato">Contato</a>
      </BellaLinks>

      <BellaMenu>
        <Heading1
          color={bellacorp_theme.colors.primary}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="fa-solid fa-bars"></i>
        </Heading1>
      </BellaMenu>
      <BellaResponsiveMenu $isopen={isOpen}>
        <div className="das">
          <Heading1
            textAlign="right"
            color={bellacorp_theme.colors.white}
            onClick={() => setIsOpen(!isOpen)}
          >
            <i className="fa-solid fa-x"></i>
          </Heading1>
          <a href="#nossos_resultados" onClick={() => setIsOpen(!isOpen)}>
            <Body1 color={bellacorp_theme.colors.white}>
              Nossos resultados
            </Body1>
          </a>
          <a href="#como_funciona" onClick={() => setIsOpen(!isOpen)}>
            <Body1 color={bellacorp_theme.colors.white}>Como funciona</Body1>
          </a>
          <a href="#regioes_trataveis" onClick={() => setIsOpen(!isOpen)}>
            <Body1 color={bellacorp_theme.colors.white}>
              Regiões que podem ser tratadas
            </Body1>
          </a>
          <a href="#contato" onClick={() => setIsOpen(!isOpen)}>
            <Body1 color={bellacorp_theme.colors.white}>Contato</Body1>
          </a>
        </div>
      </BellaResponsiveMenu>
    </BellaCorpHeaderContent>
  );
};

export default BellaCorpHeader;
