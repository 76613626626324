// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWO2h_1BObK4FplFZbvb-Wz6FjXBL9pM8",
  authDomain: "belacorpcrio.firebaseapp.com",
  projectId: "belacorpcrio",
  storageBucket: "belacorpcrio.appspot.com",
  messagingSenderId: "719154359805",
  appId: "1:719154359805:web:6efd7bdc8a2730dff1bd94",
  measurementId: "G-M87QJ6JC52",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = () => {
  getAnalytics(app);
};
