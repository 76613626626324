import { ThemePallete } from "shared/models/theme_colors.model";

class ThemeControler {
  colors: ThemePallete = {
    primary: "#ba9f47",
    secundary: "#ebdca4",
    tertiary: "#ac933e",
    black: "#707070",
    white: "#FFF",
  };
}

const bellacorp_theme = new ThemeControler();

export default bellacorp_theme;
