import React, { createContext } from "react";
import { ScaffoldComponent } from "./style";
import bellacorp_theme from "style/theme_colors";
export const ThemeProvider = createContext(bellacorp_theme);

const Scaffold: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider.Provider value={bellacorp_theme}>
      <ScaffoldComponent>{children}</ScaffoldComponent>
    </ThemeProvider.Provider>
  );
};

export default Scaffold;
