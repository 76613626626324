import styled from "styled-components";

export const HomeContent = styled.div`
  position: relative;

  .content_image img {
    position: fixed;
    z-index: 999999;
    width: 110px;
    height: 110px;
    bottom: 50px;
    right: 40px;
    animation: tremor 0.2s infinite;
  }

  @keyframes tremor {
    0% {
      transform: translate(0px, 0px);
    }
    25% {
      transform: translate(-2px, 2px);
    }
    50% {
      transform: translate(2px, -2px);
    }
    75% {
      transform: translate(-2px, -2px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }

  @media screen and (max-width: 800px) {
    .content_image img {
      width: 90px;
      height: 90px;
      bottom: 10px;
      right: 10px;
    }
  }
`;
