import bellacorp_theme from "style/theme_colors";
import styled from "styled-components";

export const Heading1 = styled.h1<{
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  lineHeight?: string;
  fontFamily?: string;
}>`
  color: ${(props) => props.color ?? bellacorp_theme.colors.black};
  font-family: ${(props) => props.fontFamily ?? "Goldplay"};
  margin: 0px;
  font-size: 40px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "1"};

  @media screen and (max-width: 1100px) {
    font-size: 30px;
  }
  @media screen and (max-width: 800px) {
    font-size: 26px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;
export const Heading2 = styled.h2<{
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  lineHeight?: string;
  fontFamily?: string;
}>`
  color: ${(props) => props.color ?? bellacorp_theme.colors.black};
  font-family: ${(props) => props.fontFamily ?? "Goldplay"};
  margin: 0px;
  font-size: 32px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "1"};

  @media screen and (max-width: 1100px) {
    font-size: 25px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
  @media screen and (max-width: 800px) {
    font-size: 20px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;
export const Heading3 = styled.h3<{
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  lineHeight?: string;
  fontFamily?: string;
}>`
  color: ${(props) => props.color ?? bellacorp_theme.colors.black};
  font-family: ${(props) => props.fontFamily ?? "Goldplay"};
  margin: 0px;
  font-size: 28px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "1"};

  @media screen and (max-width: 1100px) {
    font-size: 20px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
  @media screen and (max-width: 800px) {
    font-size: 18px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;
export const Heading4 = styled.h4<{
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  lineHeight?: string;
  fontFamily?: string;
}>`
  color: ${(props) => props.color ?? bellacorp_theme.colors.black};
  font-family: ${(props) => props.fontFamily ?? "Goldplay"};
  margin: 0px;
  font-size: 24px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "1"};
  @media screen and (max-width: 1100px) {
    font-size: 18px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;
export const Heading5 = styled.h5<{
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  lineHeight?: string;
  fontFamily?: string;
}>`
  color: ${(props) => props.color ?? bellacorp_theme.colors.black};
  font-family: ${(props) => props.fontFamily ?? "Goldplay"};
  margin: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "1"};
  @media screen and (max-width: 1100px) {
    font-size: 14px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
  @media screen and (max-width: 800px) {
    font-size: 13px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;

export const Heading6 = styled.h6<{
  color?: string;
  fontWeight?: number;
  textAlign?: string;
  lineHeight?: string;
  fontFamily?: string;
}>`
  color: ${(props) => props.color ?? bellacorp_theme.colors.black};
  font-family: ${(props) => props.fontFamily ?? "Goldplay"};
  margin: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? 400};
  text-align: ${(props) => props.textAlign ?? "left"};
  line-height: ${(props) => props.lineHeight ?? "1"};
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: normal;
    text-align: ${(props) => props.textAlign ?? "center"};
  }
`;
