import styled from "styled-components";
import image from "../../../../assets/webp/fotosite_bv-1-min.webp";

export const ContentWithHeader = styled.div`
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 6%,
    rgba(229, 233, 232, 1) 35%
  );
`;

export const PrimeiraParteContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  height: 600px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 0px 20px;
  align-items: center;

  .titleMax {
    padding: 0px 0px 8px 0px;
  }
  .title {
    padding: 0px 0px 30px 0px;
  }
  .text2 {
    padding: 0px 0px 20px 0px;
  }

  .imasddas {
    float: left;
    position: relative;
    min-height: 1px;
    width: 483px;
    height: 370px;
  }

  .image_content {
    overflow: hidden;
    width: 100%;
    height: 100%;
    clip-path: polygon(
      87.2% 12.6%,
      89.1% 15.2%,
      90.9% 18%,
      92.6% 21.1%,
      94.1% 24.4%,
      95.5% 27.8%,
      96.7% 31.3%,
      97.7% 35%,
      98.5% 38.7%,
      99.2% 42.5%,
      99.7% 46.3%,
      100% 50.1%,
      100.1% 53.8%,
      99.9% 57.5%,
      99.5% 61.1%,
      98.9% 64.5%,
      98% 67.8%,
      96.9% 70.9%,
      95.6% 73.8%,
      94.1% 76.6%,
      92.4% 79.2%,
      90.5% 81.6%,
      88.5% 83.8%,
      86.3% 85.9%,
      84% 87.8%,
      81.6% 89.6%,
      79.1% 91.2%,
      76.6% 92.7%,
      74.1% 94%,
      71.5% 95.2%,
      68.9% 96.2%,
      66.4% 97.1%,
      63.9% 97.9%,
      61.5% 98.5%,
      59.1% 99%,
      56.8% 99.4%,
      54.5% 99.7%,
      52.3% 99.9%,
      50.1% 100%,
      47.9% 99.9%,
      45.8% 99.7%,
      43.7% 99.4%,
      41.6% 99%,
      39.5% 98.5%,
      37.4% 97.9%,
      35.3% 97.2%,
      33.2% 96.5%,
      31% 95.7%,
      28.8% 94.8%,
      26.6% 93.8%,
      24.4% 92.7%,
      22.2% 91.5%,
      20% 90.2%,
      17.8% 88.9%,
      15.7% 87.5%,
      13.6% 86%,
      11.6% 84.4%,
      9.7% 82.7%,
      7.9% 80.9%,
      6.3% 79%,
      4.8% 77%,
      3.5% 74.9%,
      2.4% 72.7%,
      1.5% 70.4%,
      0.8% 68%,
      0.4% 65.4%,
      0% 62.7%,
      0% 60%,
      0.2% 57.2%,
      0.6% 54.4%,
      1.2% 51.5%,
      2% 48.6%,
      2.9% 45.7%,
      4% 42.8%,
      5.2% 39.9%,
      6.5% 37.1%,
      7.9% 34.3%,
      9.4% 31.6%,
      11% 29%,
      12.6% 26.5%,
      14.3% 24.1%,
      16% 21.8%,
      17.8% 19.6%,
      19.6% 17.6%,
      21.4% 15.7%,
      23.3% 13.9%,
      25.2% 12.2%,
      27.2% 10.6%,
      29.2% 9.2%,
      31.3% 7.9%,
      33.4% 6.7%,
      35.6% 5.6%,
      37.8% 4.6%,
      40.1% 3.7%,
      42.4% 2.9%,
      44.8% 2.2%,
      47.3% 1.6%,
      49.9% 0.9%,
      52.5% 0.5%,
      55.1% 0.2%,
      57.7% 0%,
      60.4% 0%,
      63.1% 0.1%,
      65.8% 0.4%,
      68.4% 0.9%,
      71% 1.6%,
      73.6% 2.5%,
      76.1% 3.6%,
      78.5% 4.9%,
      80.9% 6.4%,
      83% 8.2%,
      85.2% 10.2%,
      87.2% 12.6%
    );
    -webkit-clip-path: polygon(
      87.2% 12.6%,
      89.1% 15.2%,
      90.9% 18%,
      92.6% 21.1%,
      94.1% 24.4%,
      95.5% 27.8%,
      96.7% 31.3%,
      97.7% 35%,
      98.5% 38.7%,
      99.2% 42.5%,
      99.7% 46.3%,
      100% 50.1%,
      100.1% 53.8%,
      99.9% 57.5%,
      99.5% 61.1%,
      98.9% 64.5%,
      98% 67.8%,
      96.9% 70.9%,
      95.6% 73.8%,
      94.1% 76.6%,
      92.4% 79.2%,
      90.5% 81.6%,
      88.5% 83.8%,
      86.3% 85.9%,
      84% 87.8%,
      81.6% 89.6%,
      79.1% 91.2%,
      76.6% 92.7%,
      74.1% 94%,
      71.5% 95.2%,
      68.9% 96.2%,
      66.4% 97.1%,
      63.9% 97.9%,
      61.5% 98.5%,
      59.1% 99%,
      56.8% 99.4%,
      54.5% 99.7%,
      52.3% 99.9%,
      50.1% 100%,
      47.9% 99.9%,
      45.8% 99.7%,
      43.7% 99.4%,
      41.6% 99%,
      39.5% 98.5%,
      37.4% 97.9%,
      35.3% 97.2%,
      33.2% 96.5%,
      31% 95.7%,
      28.8% 94.8%,
      26.6% 93.8%,
      24.4% 92.7%,
      22.2% 91.5%,
      20% 90.2%,
      17.8% 88.9%,
      15.7% 87.5%,
      13.6% 86%,
      11.6% 84.4%,
      9.7% 82.7%,
      7.9% 80.9%,
      6.3% 79%,
      4.8% 77%,
      3.5% 74.9%,
      2.4% 72.7%,
      1.5% 70.4%,
      0.8% 68%,
      0.4% 65.4%,
      0% 62.7%,
      0% 60%,
      0.2% 57.2%,
      0.6% 54.4%,
      1.2% 51.5%,
      2% 48.6%,
      2.9% 45.7%,
      4% 42.8%,
      5.2% 39.9%,
      6.5% 37.1%,
      7.9% 34.3%,
      9.4% 31.6%,
      11% 29%,
      12.6% 26.5%,
      14.3% 24.1%,
      16% 21.8%,
      17.8% 19.6%,
      19.6% 17.6%,
      21.4% 15.7%,
      23.3% 13.9%,
      25.2% 12.2%,
      27.2% 10.6%,
      29.2% 9.2%,
      31.3% 7.9%,
      33.4% 6.7%,
      35.6% 5.6%,
      37.8% 4.6%,
      40.1% 3.7%,
      42.4% 2.9%,
      44.8% 2.2%,
      47.3% 1.6%,
      49.9% 0.9%,
      52.5% 0.5%,
      55.1% 0.2%,
      57.7% 0%,
      60.4% 0%,
      63.1% 0.1%,
      65.8% 0.4%,
      68.4% 0.9%,
      71% 1.6%,
      73.6% 2.5%,
      76.1% 3.6%,
      78.5% 4.9%,
      80.9% 6.4%,
      83% 8.2%,
      85.2% 10.2%,
      87.2% 12.6%
    );
  }

  .image_content img {
    width: 100%;
    height: 100%;
  }

  .trem_img {
    background-image: linear-gradient(28deg, #b495324f 10%, 50%, #00000021 100%),
      url(${image});
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .button {
    margin-top: 40px;
  }

  @media screen and (max-width: 800px) {
    .trem_img {
      background-position: top;
    }
    max-width: 1100px;
    margin: 0 auto;
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 0px 20px;
    align-items: center;
    text-align: center;
    .titleMax {
      padding: 0px 0px 8px 0px;
    }
    .title {
      padding: 0px 0px 30px 0px;
    }
    .text2 {
      padding: 0px 0px 20px 0px;
    }
    .alterTres {
      text-align: center;
      margin: 0 auto;
    }
    .imasddas {
      float: left;
      position: relative;
      min-height: 1px;
      width: 100%;
      height: 270px;
      padding: 30px 0px 30px 0px;
    }
    .text_content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .button {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
`;
