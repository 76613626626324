import styled from "styled-components";

export const AtendimentoHumanizadoContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;
  padding-bottom: 70px;
  width: 100%;
  .imageContent img {
    width: 80%;
  }

  .imageContent {
    text-align: center;
  }
  .imageContent1 img {
    width: 80%;
  }

  .imageContent1 {
    text-align: center;
    display: none;
  }

  .pra_quem {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 20px;
    align-items: center;
  }
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding-bottom: 70px;
    .imageContent {
      padding: 0px 16px;
    }
  }
  @media screen and (max-width: 800px) {
    .imageContent1 {
      display: block;
    }
    .imageContent {
      display: none;
    }
    padding-bottom: 20px;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    .imageContent {
      padding: 0px 16px;
    }
  }
`;
export const AtendimentoHumanizadoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  img {
    width: 123px;
    margin: 0;
    padding: 0 0 14px;
  }
  @media screen and (max-width: 1100px) {
    padding: 0px 16px;
  }
  @media screen and (max-width: 800px) {
    padding: 0px 16px;
    align-items: center;
    h3 {
      text-align: center;
    }
    .asdsad {
      text-align: center;
    }
  }
`;

export const CardAtendimentoContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  height: 100%;
  .text_content {
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: flex-start;
    align-content: center;
    flex-direction: column;
  }
`;
