import bellacorp_theme from "style/theme_colors";
import styled from "styled-components";

export const BellaCorpHeaderContent = styled.header`
  height: 92px;
  display: flex;
  align-items: center;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    height: 82px;
    display: flex;
    align-items: center;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0px;
    justify-content: space-around;
    flex-direction: row;
  }
`;

export const HeaderLogos = styled.div`
  img {
    width: 132px;
    max-height: 92px;
  }
  @media screen and (max-width: 800px) {
    img {
      width: 92px;
      max-height: 92px;
      padding-right: 71px;
    }
  }
`;

export const BellaLinks = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;

  a {
    color: #000;
    letter-spacing: 0.4px;
    font-size: 13px;
    text-transform: uppercase;
    margin: 0% 0% 0% 0%;
    text-decoration: none;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const BellaMenu = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
  }
`;
export const BellaResponsiveMenu = styled.div<{
  $isopen: boolean;
}>`
  position: fixed;
  height: 100vh;
  width: ${(props) => (props.$isopen ? "100%" : "0%")};
  z-index: 100;
  background-color: ${bellacorp_theme.colors.primary};
  transition: 0.4s;
  top: 0;
  right: 0;

  overflow: hidden;

  .das {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 21px;
  }

  .das > div {
    justify-content: flex-start;
  }
  a {
    color: #000;
    letter-spacing: 0.4px;
    font-size: 13px;
    text-transform: uppercase;
    margin: 0% 0% 0% 0%;
    text-decoration: none;
  }
`;
