import React from "react";
import { HomeContent } from "./style";
import SegundaParte from "./components/segunda_parte";
import TerceiraParte from "./components/terceira_parte";
import Footer from "./components/footer";
import PrimeiraParte from "./components/primeira_parte";
import QuartaParte from "./components/quarta_parte";
import image from "../../assets/webp/whatsapp-logo-icon-isolated-on-transparent-background-free-png.webp";

const Home: React.FC = () => {
  return (
    <HomeContent>
      <PrimeiraParte />
      <SegundaParte />
      <TerceiraParte />
      <QuartaParte />
      <Footer />
      <div className="content_image">
        <a
          href="https://api.whatsapp.com/send?phone=5521993241111&text=Ol%C3%A1,%20quero%20agendar%20um%20hor%C3%A1rio!"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={image} alt="" />
        </a>
      </div>
    </HomeContent>
  );
};

export default Home;
