import { Body2, Body1 } from "components/text/body/style";
import { Heading4 } from "components/text/heading/style";
import bellacorp_theme from "style/theme_colors";
import { FooterContent } from "./style";
import React from "react";

const Footer: React.FC = () => {
  return (
    <FooterContent id="contato">
      <section>
        <div className="divFlex">
          <Heading4 textAlign="left" color={bellacorp_theme.colors.white}>
            A Bella Corp
          </Heading4>
          <Body2 textAlign="left" color={bellacorp_theme.colors.white}>
            Com unidades em Belo Horizonte e no Rio de Janeiro, a Bella Corp tem
            a missão de te trazer saúde e bem estar.
          </Body2>
          <Body2 textAlign="left" color={bellacorp_theme.colors.white}>
            Estética avançada - CNPJ: 48.704766/0001-83
          </Body2>
          <Body2 textAlign="left" color={bellacorp_theme.colors.white}>
            Av. das Américas, 3500 - Barra da Tijuca, Rio de Janeiro - RJ. Bloco
            4, portaria 2 - sala 522
          </Body2>
          <Body2 textAlign="left" color={bellacorp_theme.colors.white}>
            CEP: 22640-102
          </Body2>
        </div>

        <div className="divFlex">
          <Heading4 textAlign="left" color={bellacorp_theme.colors.white}>
            Central de atendimento
          </Heading4>
          <Body2 textAlign="left" color={bellacorp_theme.colors.white}>
            <b>E-mail:</b> admin@bellacorp.com.br
          </Body2>
          <Body2 textAlign="left" color={bellacorp_theme.colors.white}>
            <b>Telefone:</b> 21 2135-1624
          </Body2>
          <Body2 textAlign="left" color={bellacorp_theme.colors.white}>
            <b>Whatsapp:</b> 21 99324-1111
          </Body2>
        </div>

        <div className="divFlex">
          <Heading4 textAlign="left" color={bellacorp_theme.colors.white}>
            Entre em contato
          </Heading4>

          <div style={{ display: "flex", gap: "20px" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/bellacorp.rj/"
            >
              <Body1 textAlign="left" color={bellacorp_theme.colors.white}>
                <i className="fa-brands fa-instagram"></i>
              </Body1>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://api.whatsapp.com/send?phone=5521993241111&text=Ol%C3%A1,%20quero%20agendar%20um%20hor%C3%A1rio!"
            >
              <Body1 textAlign="left" color={bellacorp_theme.colors.white}>
                <i className="fa-brands fa-whatsapp"></i>
              </Body1>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/bellacorp.rj"
            >
              <Body1 textAlign="left" color={bellacorp_theme.colors.white}>
                <i className="fa-brands fa-square-facebook"></i>
              </Body1>
            </a>
          </div>
          <Heading4 textAlign="left" color={bellacorp_theme.colors.white}>
            Horário de funcionamento
          </Heading4>
          <Body2 textAlign="left" color={bellacorp_theme.colors.white}>
            <b>Segunda a sexta:</b> de 09:00 ás 18:00
          </Body2>
          <Body2 textAlign="left" color={bellacorp_theme.colors.white}>
            <b>Sábado:</b> de 08:00 ás 14:00
          </Body2>
        </div>
      </section>
    </FooterContent>
  );
};

export default Footer;
