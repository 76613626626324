import { CallToActionAction, CallToActionContent } from "./style";
import React from "react";

import { Body1 } from "components/text/body/style";
import bellacorp_theme from "style/theme_colors";

const CallToAction: React.FC<{
  label: string;
  color?: "azul" | "dourado" | undefined;
}> = ({ label, color }) => {
  return (
    <CallToActionAction
      className="ADsdasdas"
      href="https://api.whatsapp.com/send?phone=5521993241111&text=Ol%C3%A1,%20quero%20agendar%20um%20hor%C3%A1rio!"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CallToActionContent
        $isSecundary={color === "dourado"}
        className="alterTres"
      >
        <Body1
          textAlign="left"
          color={
            color === "dourado"
              ? bellacorp_theme.colors.white
              : bellacorp_theme.colors.tertiary
          }
        >
          <i className="fa-brands fa-whatsapp"></i>
        </Body1>
        <Body1
          color={
            color === "dourado"
              ? bellacorp_theme.colors.white
              : bellacorp_theme.colors.tertiary
          }
          fontWeight={400}
        >
          {label}
        </Body1>
      </CallToActionContent>
    </CallToActionAction>
  );
};

export default CallToAction;
