import React from "react";

import Scaffold from "components/scaffold";
import Home from "view/Home";
import { analytics } from "service/firebase";

const App: React.FC = () => {
  analytics();
  return (
    <Scaffold>
      <Home />
    </Scaffold>
  );
};

export default App;
