import { Body2 } from "components/text/body/style";
import React from "react";
import bellacorp_theme from "style/theme_colors";
import { CardAtendimentoContent } from "../style";
import { Heading4 } from "components/text/heading/style";

const CardPraQuem: React.FC<{
  text: string;
}> = ({ text }) => {
  return (
    <CardAtendimentoContent>
      <Heading4 textAlign="left" color={bellacorp_theme.colors.primary}>
        <i className="fa-solid fa-check"></i>
      </Heading4>
      <div className="text_content">
        <Body2 textAlign="left" color={bellacorp_theme.colors.black}>
          {text}
        </Body2>
      </div>
    </CardAtendimentoContent>
  );
};

export default CardPraQuem;
