// globalStyles.js
import bellacorp_theme from "style/theme_colors";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


html {
    scroll-behavior: smooth;
    outline: none !important
}

* {
    font-family: Goldplay, sans-serif;
}

input,
button,
ul,
li {
    outline: none !important;
}

a:hover {
    text-decoration: none !important
}

ol,
ul {
    margin: 0px;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}


body {
  font-optical-sizing: auto;
  height: 100%;
  margin: 0px;
}

html {
  height: 100%;
}

html,
html * {
  scrollbar-width: thin;
  scrollbar-color: ${bellacorp_theme.colors.primary} transparent;
}

.html::-webkit-scrollbar {
  width: 3px;
  height: 3px; /* A altura só é vista quando a rolagem é horizontal */
}

.html::-webkit-scrollbar-track {
  background: transparent;
  padding: 2px;
}

.html::-webkit-scrollbar-thumb {
  background-color: #000;
}


.swiper {
    max-width: 100%;
}.swiper-button-prev, .swiper-button-next {

    color: ${bellacorp_theme.colors.primary};
}.swiper-pagination-bullet-active {
    background: ${bellacorp_theme.colors.primary};
}
`;

export default GlobalStyle;
