import { Body2, Body1 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import React from "react";
import bellacorp_theme from "style/theme_colors";
import {
  AtendimentoHumanizadoContent,
  AtendimentoHumanizadoText,
} from "./style";

import atendimento_image from "../../../../assets/webp/modelo_clio-min.webp";

import CallToAction from "components/call_to_action";
import CardPraQuem from "./components/card_pra_quem";

const QuartaParte: React.FC = () => {
  return (
    <AtendimentoHumanizadoContent>
      <AtendimentoHumanizadoText>
        <div className="asdsad">
          <Body2 textAlign="left" color={bellacorp_theme.colors.primary}>
            Conheça mais sobre a criomodelagem
          </Body2>
          <Heading3
            textAlign="left"
            color={bellacorp_theme.colors.primary}
            fontFamily={"GoldplaySemibold"}
            lineHeight={"1.2"}
          >
            Regiões que pode ser tratadas com a Criomodelagem
          </Heading3>
        </div>
        <div className="asdsad">
          <Body2 textAlign="left" color={bellacorp_theme.colors.black}>
            A Criomodelagem é um procedimento versátil, pois conseguimos tratar
            diversas regiões que acumulam gordura localizada. Utilizamos a crio
            de placas.
          </Body2>
        </div>
        <Body2 textAlign="left" color={bellacorp_theme.colors.black}>
          Qual dessas regiões você gostaria de tratar?
        </Body2>
        <div className="imageContent1">
          <img
            src={atendimento_image}
            alt="Qual dessas regiões você gostaria de tratar?"
            loading="lazy"
          />
        </div>
        <Body1 textAlign="left" color={bellacorp_theme.colors.primary}>
          <b>Criolipólise</b> é para você:
        </Body1>
        <div className="pra_quem">
          <CardPraQuem text="Que não consegue perder barriga mesmo se matando na academia." />
          <CardPraQuem text="Que tentou de tudo mas não consegue eliminar gordura localizada." />
          <CardPraQuem text="Que Deseja conquistar sua melhor versão e viver com mais conforto." />
          <CardPraQuem text="Quer perder gordura de forma rápida e não tem tempo para fazer vários procedimentos." />
        </div>
        <Body1 textAlign="left" color={bellacorp_theme.colors.primary}></Body1>
        <CallToAction label={"Agendar Avaliação"} color="dourado" />
      </AtendimentoHumanizadoText>

      <div className="imageContent">
        <img
          src={atendimento_image}
          alt="Qual dessas regiões você gostaria de tratar?"
          loading="lazy"
        />
      </div>
    </AtendimentoHumanizadoContent>
  );
};

export default QuartaParte;
